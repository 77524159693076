var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"workbench-wrap"},[_c('div',{staticClass:"charts-top"},[_c('div',{staticClass:"my-chart-wrap my-chart-wrap1"},[_vm._m(0),_c('div',{ref:"myChartA",staticClass:"my-chart-content"},[_vm._m(1)])]),_c('div',{staticClass:"my-chart-wrap my-chart-wrap2"},[_vm._m(2),_c('div',{ref:"myChartB",staticClass:"my-chart-content"},[_vm._m(3)])])]),_c('div',{staticClass:"my-chart-wrap my-chart-wrap3"},[_vm._m(4),_c('div',{ref:"myChartC",staticClass:"my-chart-content"},[_vm._m(5)])]),_c('div',{staticClass:"my-chart-wrap my-chart-wrap4"},[_vm._m(6),_c('div',{ref:"myChartD",staticClass:"my-chart-content"},[_vm._m(7)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"title"},[_c('span'),_vm._v("项目类型统计")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nodata"},[_c('span',[_vm._v("暂无数据")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"title"},[_c('span'),_vm._v("项目进度统计")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nodata"},[_c('span',[_vm._v("暂无数据")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"title"},[_c('span'),_vm._v("历年项目总数")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nodata"},[_c('span',[_vm._v("暂无数据")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"title"},[_c('span'),_vm._v("各科室申请项目统计")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nodata"},[_c('span',[_vm._v("暂无数据")])])
}]
render._withStripped = true
export { render, staticRenderFns }